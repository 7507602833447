<template>
<div>
        <loading :active.sync="loading"></loading>
        <div class="row"><div class="col-md-12">
          <b-card>
            <div slot="header">
            <CRow>
                <CCol sm="6">
                    <strong>Mão de Obra</strong>
                </CCol>
                <CCol sm="6" style="text-align:right;">
                    <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
                </CCol>
                </CRow>
          </div>

        <table class="table table-hover table-sm">
            <thead>
            <tr>
                <th width="2%"></th>
                <th width="15%">Nome</th>
                <th width="10%">Função</th>
                <th width="5%">Cód.</th>
                <th width="10%">Salário</th>
                <th width="15%">Total</th>
                <th width="7%">Qt</th>
                <th width="7%">Ócio</th>
                <th width="10%">Custo/H</th>
                <th width="10%">Custo/Min.</th>
                <th width="10%" colspan="2"></th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(maodeobraItem, index) in mao" :key="index">
                    <td>
                      <b-form-checkbox size="sm"
                        v-model="mao[index].mao_active" 
                        switch
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-input v-model="mao[index].mao_name" size="sm"></b-form-input>
                    </td>
                    <td>
                      <b-form-input v-model="mao[index].mao_funcao" size="sm"></b-form-input>
                    </td>
                    <td>
                      <b-form-input v-model="mao[index].mao_codigo" size="sm"></b-form-input>
                    </td>
                    <td>
                      <vue-autonumeric @blur.native="calculaTotal()" class="form-control" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_salario" size="sm"></vue-autonumeric>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control" readonly style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_total" size="sm"></vue-autonumeric>
                    </td>
                    <td>
                      <b-form-input @blur.native="calculaTotal()" type="number" v-model="mao[index].mao_qt" size="sm"></b-form-input>
                    </td>
                    <td>
                      <vue-autonumeric @blur.native="calculaTotal()" class="form-control input-sm" style="font-size:10px;" :options="perc" v-model="mao[index].mao_ocio" size="sm"></vue-autonumeric>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control input-sm" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_custohora" readonly></vue-autonumeric>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control input-sm" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_custominuto" readonly></vue-autonumeric>
                    </td>
                    <td>
                        <button class="btn btn-warning btn-sm" @click="editar(index)" size="sm">Salvar</button>
                    </td>
                    <td>
                        <button class="btn btn-sm" v-b-modal="'modal-detalhes'+index">Mais...</button>
                        <b-modal :id="'modal-detalhes'+index" title="Benefícios">
                            <b-container>
                        <b-row cols="3">
                        <bcol><strong>Encargos:</strong></bcol>
                        <bcol>
                            <b-form-select v-model="mao[index].enc_ID" :options="optionsEnc" size="sm"></b-form-select>
                        </bcol>
                        <bcol>
                            <vue-autonumeric class="form-control" style="font-size:10px;" :options="dinheiro" readonly v-model="mao[index].mao_enc_total" size="sm"></vue-autonumeric>
                        </bcol>
                        </b-row>
                        <p><strong>Benefícios:</strong></p>
                        <b-row cols="2">
                        <bcol>Vale Transporte</bcol>
                        <bcol>
                        <vue-autonumeric @blur.native="calculaTotal()" class="form-control" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_vale1" size="sm"></vue-autonumeric>
                        </bcol>
                        <bcol>Vale Alimentação</bcol>
                        <bcol>
                        <vue-autonumeric @blur.native="calculaTotal()" class="form-control" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_vale2" size="sm"></vue-autonumeric>
                        </bcol>
                        <bcol>Convênio</bcol>
                        <bcol>
                        <vue-autonumeric @blur.native="calculaTotal()" class="form-control" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_vale3" size="sm"></vue-autonumeric>
                        </bcol>
                        <bcol>Outros 1</bcol>
                        <bcol>
                        <vue-autonumeric @blur.native="calculaTotal()" class="form-control" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_vale4" size="sm"></vue-autonumeric>
                        </bcol>
                        <bcol>Outros 2</bcol>
                        <bcol>
                        <vue-autonumeric @blur.native="calculaTotal()" class="form-control" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_vale5" size="sm"></vue-autonumeric>
                        </bcol>
                        </b-row>
                            </b-container>
                        </b-modal>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td><b-form-input v-model="maoName" size="sm"></b-form-input></td>
                    <td><b-form-input v-model="maoFuncao" size="sm"></b-form-input></td>
                    <td><b-form-input v-model="maoCodigo" size="sm"></b-form-input></td>
                    <td><vue-autonumeric class="form-control input-sm" style="font-size:10px;" v-model="maoSalario"></vue-autonumeric></td>
                    <td colspan="5"></td>
                    <td colspan="2"><button class="btn btn-success btn-sm" @click="adicionar()">Adicionar</button></td>
                </tr>
            </tbody>
        </table>
          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Mão de Obra">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/4-JyRPcvIIU?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div></div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'

export default {
  data () {
    return {
      maodeobraItem: [],
      sessao: '',
      loading: false,
      mao: [],
      maoCodigo: 0,
      maoName: '',
      maoFuncao: '',
      maoSalario: 0,
      maoActive: 1,
      optionsEnc: [],
      maoval: [],
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      perc: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.recarregar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    recarregar: function () {
      this.optionsEnc = []
      this.listar('pro_Maodeobra', 'mao_funcao, mao_name', '').then(
        (response) => {
        this.mao = response
        this.listar('pro_Encargo', 'enc_ID', 'enc_active >= 0').then(
            (r) => {
                this.optionsEnc.push({ value: 0, text: 'Nenhum', disabled: 0 })
                for(var i = 0; i < r.length; i++) {
                    this.optionsEnc.push({ value: r[i].enc_ID, text: r[i].enc_name + ' (' + r[i].enc_total + '%)', disabled: !r[i].enc_active })
                    this.maoval[r[i].enc_ID] = r[i].enc_total
                }
                this.calculaTotal()
                this.loading = false
            },
            (r) => {
                this.$notify({
                    type: 'danger',
                    title: 'Dados básicos necessários',
                    text: 'Cadastre encargos antes.'
                })
                this.loading = false
            }
        )
        }, (response) => {})
    },
    adicionar: function () {
      if (this.maoFuncao === '' || this.maoCodigo === '' || this.maoSalario === 0) {
        this.$swal('Preencha os campos obrigatórios: função, código e salário')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Maodeobra', 'mao_ID', '(mao_codigo = ' + this.maoCodigo + ')').then(
        (response) => {
          this.$swal('Já existe um registro com este código: ID ' + response + '')
          return false
        }, (response) => {
          this.loading = true
          this.inserir('pro_Maodeobra', {resource: [ {mao_name: this.maoName, mao_funcao: this.maoFuncao, 
          mao_codigo: this.maoCodigo, mao_salario: this.maoSalario, mao_active: 1} ]}).then(
            (response) => {
              this.maoName = ''
              this.maoCodigo = ''
              this.maoFuncao = ''
              this.maoSalario = 0
              this.recarregar()
            },
            (response) => {
              this.$swal('Erro ao inserir: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    },
    editar: function (index) {
      // Verifica se já existe
      this.campo('pro_Maodeobra', 'mao_ID', '(mao_codigo = ' + this.mao[index].mao_codigo + ') AND (mao_ID != ' + this.mao[index].mao_ID + ')').then(
        (response) => {
          this.$swal('Já existe um registro com este código: ID ' + response + '')
          return false
        }, (response) => {
          this.loading = true
          this.atualizar('pro_Maodeobra', {resource: [ {mao_name: this.mao[index].mao_name, 
          mao_funcao: this.mao[index].mao_funcao, mao_codigo: this.mao[index].mao_codigo, mao_salario: this.mao[index].mao_salario, 
          mao_active: this.mao[index].mao_active, enc_ID: this.mao[index].enc_ID, mao_vale1: this.mao[index].mao_vale1, mao_vale2: this.mao[index].mao_vale2, mao_vale3: this.mao[index].mao_vale3,
          mao_vale4: this.mao[index].mao_vale4, mao_vale5: this.mao[index].mao_vale5, 
          mao_total: this.mao[index].mao_total, mao_qt: this.mao[index].mao_qt, 
          mao_ocio: this.mao[index].mao_ocio, mao_custohora: this.mao[index].mao_custohora, mao_customin: this.mao[index].mao_customin} ]}, 'mao_ID = ' + this.mao[index].mao_ID).then(
            (response) => {
              this.recarregar()
            },
            (response) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    },
    calculaTotal: function() {
      var encargo = 0
      for (var n = 0; n < this.mao.length; n++) {
        encargo = this.maoval[this.mao[n].enc_ID]
        // Encargos
        this.mao[n].mao_enc_total = (this.mao[n].mao_salario === 0 ? this.mao[n].mao_salario : ((this.mao[n].mao_salario * (100 + encargo)) / 100))
        // Total
        this.mao[n].mao_total = (this.mao[n].mao_enc_total + this.mao[n].mao_vale1 + this.mao[n].mao_vale2 + this.mao[n].mao_vale3 + this.mao[n].mao_vale4 + this.mao[n].mao_vale5)
        // Custo hora
        this.mao[n].mao_custohora = (this.mao[n].mao_total === 0 ? 0 : ((this.mao[n].mao_total / this.mao[n].mao_qt / (100 - this.mao[n].mao_ocio)) * 100))
        // Custo minuto
        this.mao[n].mao_custominuto = this.mao[n].mao_custohora / 60
      }
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading,
    VueAutonumeric
  }
}
</script>